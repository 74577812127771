@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@1,300&display=swap");

.reportlost-container {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.reportlost-one-line {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.reportlost-input {
  width: 50%;
}

.reportlost-container-pet-details-span,
.reportlost-container-pet-parent-details-span,
.reportlost-container-owner-reward-span {
  display: flex;
  flex-direction: column;
  gap: 1.2em;
}

.reportlost-container-pet-details-span > h4,
.reportlost-container-pet-parent-details-span > h4,
.reportlost-container-owner-reward-span > h4 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.4em;
  margin-top: 1em;
}

.reportlost-container-pet-details-span-input-cnt,
.reportlost-container-pet-parent-details-span-input-cnt,
.reportlost-container-owner-reward-span-select-cnt {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.reportlost-container-pet-details-span-input-cnt > span,
.reportlost-container-pet-parent-details-span-input-cnt > span,
.reportlost-container-owner-reward-span-select-cnt > span {
  text-align: left;
  font-size: 1.25rem;
}

.reportlost-container-pet-details-span-input-cnt > input,
.reportlost-container-pet-parent-details-span-input-cnt > input,
.reportlost-container-owner-reward-span-select-cnt > select,
.reportlost-container-owner-reward-span-select-cnt > input {
  padding: 1rem 2rem !important;
  border-radius: 5px;
  border: 1px solid black;
  background-color: white;
}

.reportlost-container-pet-details-span-input-cnt > input,
.reportlost-container-pet-parent-details-span-input-cnt > input,
.reportlost-container-owner-reward-span-select-cnt > input {
  margin-top: 0 !important;
}

.reportlost-container-owner-reward-btns {
  /* margin: 1em 0; */
  display: flex;
  gap: 1em;
}

.date-time-loss {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.date-time-loss-input {
  padding: 1em 2em !important;
  border: 1px solid black;
  border-radius: 5px;
}

.date-time-loss-time-picker {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
}

.date-time-loss-btns {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1em;
}

.reportlost-location {
  position: relative;
  height: 30em;
}

.reportlost-location-map-container {
  position: absolute;
  width: 100%;
  height: 16.5em;
}

.reportlost-location-input-cnt {
  display: none;
  /* position: absolute;
  top: 17em;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1em; */
}

.reportlost-location-input-cnt-lat,
.reportlost-location-input-cnt-lon {
  display: none;
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
}

.reportlost-location-input-cnt-lat > span,
.reportlost-location-input-cnt-lon > span {
  font-size: 1.1875rem;
}

.reportlost-location-btns {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1em;
  padding: 1rem;
}

.reportlost-location-input {
  margin-top: 0px !important;
  padding: 1em 2em !important;
  border: 1px solid black;
  border-radius: 5px;
}

.reportlost-loss-details-btns {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1em;
}

.reportlost-loss-details {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.reportlost-loss-details > textarea {
  padding: 3em;
  border: 1px solid black;
  border-radius: 5px;
}

/* Yashvi key css start */
.reportLost-main-wrapper {
  width: 100%;
  /* position: absolute; */
  padding: 5.3rem 0rem;
  background-color: #c7c7c781;
}

/* .reportLost-main-form-div{

} */

.main-form {
  background-color: white;
  border-radius: 10px;
  margin-top: 3rem;
  padding: 1rem;
}

.reportLost-left-wrapper {
  position: relative;
}

.reportLost-sidebar-container {
  width: 100%;
  height: 100vh;
  z-index: 800;
  position: absolute;
  background-color: #e6e5e3;
  display: flex;
  flex-direction: column;
  left: 0;
  transition: 350ms;
}

.reportLost-sidebar-container-hide {
  width: 100%;
  height: 100vh;
  z-index: 800;
  position: absolute;
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  left: -100%;
  transition: 350ms;
}

.reportLost-sidebar-toggle-btn-wrapper {
  padding: 1rem;
  width: 100%;
}

.reportLost-sidebar-toggle-btn {
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
}

.reportLost-sidebar-menu-link {
  background-color: transparent;
  border: none;
  padding: 1rem;
  text-align: left;
  font-size: 1.21875rem;
}

.reportLost-sidebar-menu-ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.reportLost-sidebar-menu-ul-hide {
  display: none;
}

.reportLost-sidebar-menu-ul-links {
  border: none;
  background-color: transparent;
  padding: 1rem 1rem 1rem 2rem;
}

.petdetails-height {
  min-height: 800px !important;
}

.background {
  min-height: 100% !important;
  height: 100%;
}
.dashboard__blacksidenav {
  width: 23rem;
  background-color: #e7e4e4;
}
.tag_inside_btn {
  height: 3rem;
  width: 7rem;
  border: none;
  outline: none;
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  background-color: #e7e4e4;
}
.tag_inside_btn:hover {
  background-color: rgb(251 249 249);
}
.profile_name {
  padding-left: 2rem;
  color: black;
  width: 100%;
  text-decoration: none;
  list-style: none;
  display: flex;
  flex-direction: left;
  align-items: center;
  height: 2.3rem;
  font-size: 1.5rem;
  margin-bottom: 0.7rem;
  margin-top: -0.4rem;
}

.profile_name:hover {
  cursor: pointer;
  background-color: rgb(251 249 249);
  text-decoration: none;
}
.tag_inside_btn > span {
  display: flex;
  justify-content: left;
  align-items: center;
  margin-left: 3rem;
  font-size: 17px;
  letter-spacing: 1px;
}

.tag1_text {
  font-size: 17px;
  border: none;
  outline: none;
}
.my-tags {
  padding-left: 2rem;
}
.my-tags1 {
  padding-left: 2rem;
  width: 100%;
  height: 3rem;
  display: flex;
  justify-content: left;
  align-items: center;
}
.my-tags1:hover {
  cursor: pointer;
  background-color: rgb(251 249 249);
}
.user-logo {
  margin-left: 8rem;
  margin-top: 1rem;
}
.ngx-timepicker-field-example {
  height: 30px !important;
  font-size: 25px !important;
}

.lostincipet {
  text-transform: none !important;
}

.mat-button-toggle {
  width: 200px;
  border: none;
  outline: none;
  word-wrap: break-word;
}

.mat-button-toggle-group {
  border: none;
  outline: none;
}

.mat-button-toggle-group:focus .mat-button-toggle-group:checked {
  outline: none !important;
  border: none !important;
}

.mat-button-toggle:focus .mat-button-toggle:checked {
  outline: none !important;
  border: none !important;
}

.mat-button-toggle-button:focus .mat-button-toggle-button:checked {
  outline: none !important;
  border: none !important;
}

.gray-color {
  color: lightgray;
}

.bg-gray {
  background-color: lightgrey;
}

.profile-sidenav {
  letter-spacing: 1px;
  font-size: 18px;
  font-weight: 400px;
}

.p-info {
  width: 33rem;
  border-radius: 5px;
}

.lightgray-color {
  background: #e9eaed;
}

.fa-user-circle-o {
  background: #ebebeb;
  color: rgb(211, 209, 209);
  border-radius: 50%;
  border: none;
  outline: none;
  margin-top: -10px;
}

.full-name {
  font-size: 22px;
  font-weight: 350px;
  margin-top: -10px;
}

.full-name-1 {
  font-size: 17px;
  font-weight: 350px;
}

.full-name-2 {
  font-size: 17px;
  font-weight: 350px;
}

.fullname-email {
  font-size: 17px;
  font-weight: 350px;
  margin-top: -23px;
}

.full-nickname {
  margin-top: 50px;
}

.nickname {
  margin-left: 40%;
}

.nickname-1 {
  margin-left: 20%;
}

.h-100 {
  height: 100%;
}

.edit-button {
  margin-left: 35%;
  color: rgb(211, 209, 209);
  border: none;
  outline: none;
  background-color: white;
}

.mobile-update {
  width: 250px;
}

.loss-details-header {
  width: 68%;
  color: white;
  margin-left: 15%;
  border: 1px solid black;
}
.loss-details-body {
  width: 68% !important;
  margin-left: 15%;
  border: 1px solid black;
  word-wrap: break-word;
  text-overflow: clip;
}
.know-where-lost {
  font-size: 16px;
}

.fa-map-marker-alt {
  margin-top: -5px;
  font-size: 18px;
  color: gray;
}

.fa-calendar-alt {
  margin-top: -5px;
  font-size: 18px;
  color: gray;
}

.fa-clock {
  margin-top: -5px;
  font-size: 18px;
  color: gray;
}

.reward-header {
  letter-spacing: 1px;
}

.reward-body {
  font-size: 17px;
}

.input-pet-details {
  border: none;
  outline: none;
  border-bottom: 2px solid #e4e4e4;
  box-shadow: none;
  outline-width: 0;
  width: 200px;
  height: 20px;
  font-size: 14px;
}

.input-pet-details-1 {
  border: none;
  outline: none;
  border-bottom: 2px solid #e4e4e4;
  box-shadow: none;
  outline-width: 0;
  width: 440px;
  height: 20px;
  font-size: 14px;
}

.input-pet-details-2 {
  border: none;
  outline: none;
  border-bottom: 2px solid #a1a1a1;
  box-shadow: none;
  outline-width: 0;
  width: 200px;
  height: 20px;
  font-size: 14px;
  background-color: rgb(255, 234, 177);
}

.input-pet-details-dropdown {
  border: none;
  outline: none;
  border-bottom: 2px solid #e4e4e4;
  width: 200px;
  height: 20px;
  box-shadow: none;
  font-size: 14px;
  padding: 10px;
  margin-top: 0.5rem;
}
.input-pet-details-dropdown > option {
  padding: 10px;
  font-size: 0.9rem;
}

.label-petdata {
  font-size: 16px;
  color: #494949;
  font-weight: 800;
  font-family: "Lato", sans-serif;
}

.label-petdata-1 {
  font-size: 16px;
  color: #494949;
  font-weight: 800;
  font-family: "Lato", sans-serif;
}

button:focus {
  border: none;
  outline: none;
  box-shadow: none;
  background-color: white;
}

button:hover {
  border: none;
  outline: none;
  box-shadow: none;
  background-color: transparent;
}

#lost-calendar-preview {
  width: 250px;
  margin-left: 35%;
}

.text-gray {
  color: #b4b4b4;
}

.inline-display {
  display: inline-block;
}

.lost-time {
  margin: auto;
  width: 200px;
}

/* input {
  padding: 0px !important;
} */

/* @media only screen and (max-width:1310px){
    .mat-button-toggle{
        width: 150px;
    }
} */

.petparentlinks-rl {
  margin-left: -30px !important;
}

.tabs-div {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}
.PrivateTabIndicator-colorSecondary-3 {
  background-color: #ffc107 !important;
  height: 2.5px !important;
}
.MuiTab-root {
  font-size: 13px;
  padding: 2px;
}

.lost-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.lost-textfield {
  width: 50% !important;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  text-align: left !important;
}
#demo-row-radio-buttons-group-label {
  margin-left: -11rem !important;
  font-size: 12px !important;
}

/* @media only screen and (min-width: 1200px) {
  .reportLost-main-wrapper {
    height: 100%;
  }
} */

.bg-gray-height {
  height: 120vh;
}

@media only screen and (max-width: 960px) {
  /* .mat-button-toggle{
        width: 100px;
    } */
  .mat-button-toggle-group {
    flex-wrap: wrap;
    margin: auto !important;
  }

  .loss-details-body {
    margin-top: 100px !important;
  }
  .dateandtime {
    margin-top: 20px !important;
  }
}

@media only screen and (max-width: 850px) {
  .mat-toggle-group-original {
    margin-top: -20px !important;
  }
  .mat-button-toggle {
    width: 150px !important;
    font-size: 14px !important;
  }
  .rightbackground {
    min-height: 1000px !important;
    height: 1000px !important;
  }
}

@media only screen and (max-width: 804px) {
  .angularlocationmap {
    height: 200px !important;
  }
}
@media screen and (min-width: 22.5em) {
  .reportlost-container-owner-reward-btns {
    margin: 1em 3em;
    /* margin: 1em 3em; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1em;
  }
}

@media only screen and (max-width: 800px) {
  #lost-calendar-preview {
    margin-left: 25%;
  }
  .mat-button-toggle {
    width: 120px !important;
    font-size: 14px !important;
  }
}

@media only screen and (max-width: 747px) {
  .marginheight-rl {
    margin-top: 0px !important;
  }
}

@media only screen and (max-width: 700px) {
  .leftside-buttons-rl {
    font-size: 16px !important;
  }
}

@media only screen and (max-width: 650px) {
  #lost-calendar-preview {
    margin-left: 15%;
  }

  .reportlostheight {
    height: 100% !important;
  }

  .mat-toggle-group-original {
    margin-top: 00px !important;
  }
}

@media only screen and (max-width: 576px) {
  #lost-calendar-preview {
    margin-left: 20%;
  }
  .marginheight-rl {
    margin-top: 15px !important;
  }
  .uppercontainer {
    width: 100% !important;
    margin-right: 0px !important;
  }
}

@media only screen and (max-width: 480px) {
  /* .mat-button-toggle{
        width: 50% !important;
      } */

  .loss-details-body {
    width: 90%;
    margin: auto;
  }
  .nextback {
    height: 35px !important;
    width: 200px !important;
  }
}

@media only screen and (max-width: 450px) {
  #lost-calendar-preview {
    margin-left: 15%;
  }

  .mat-raised-button {
    width: 200px !important;
  }
  .lost-textfield {
    width: 100% !important;
  }

  .lost-div {
    flex-direction: column;
  }
  .gm-style-mtc > button {
    font-size: 14px !important;
    padding: 0.5rem !important;
  }
}

@media only screen and (max-width: 415px) {
  .mat-button-toggle {
    width: 160px !important;
    font-size: 14px !important;
    height: 40px !important;
  }
}

@media only screen and (max-width: 400px) {
  #lost-calendar-preview {
    margin-left: 10%;
  }

  .lost-time {
    width: 100%;
  }

  .ngx-timepicker-field-example-col {
    margin-left: -50px !important;
  }
}

@media only screen and (max-width: 380px) {
  .timerangeofloss {
    margin-top: -30px !important;
  }
  .reward_div {
    margin-left: -40px !important;
  }
  .set-time {
    font-size: 22px !important;
  }
  .lost-time-heading {
    font-size: 19px;
  }
}

@media only screen and (max-width: 350px) {
  #lost-calendar-preview {
    margin-left: 15%;
    width: 200px;
  }

  .inline-display-1 {
    display: inline-block;
  }

  .rewardcss {
    width: 200px !important;
  }

  .set-time {
    font-size: 20px !important;
  }
  .lost-time-heading {
    font-size: 17px;
  }
}

@media only screen and (max-width: 312px) {
  #lost-calendar-preview {
    margin-left: 10%;
    width: 200px;
  }

  .lost-time {
    width: 100%;
  }
  .ngx-timepicker-field-example-col {
    margin-left: -60px !important;
  }

  .reward_div {
    margin-left: -55px !important;
  }

  .set-time {
    font-size: 16px !important;
  }
  .lost-time-heading {
    font-size: 16px;
  }
}

@media only screen and (max-width: 290px) {
  #lost-calendar-preview {
    margin-left: 8%;
  }

  .mat-raised-button {
    width: 150px !important;
  }

  .ngx-timepicker-field-example-col {
    margin-left: -65px !important;
  }

  .reward_div {
    margin-left: -50px !important;
  }

  .reward_div input,
  .reward_div select {
    width: 180px !important;
  }

  .set-time {
    font-size: 13px !important;
    margin-left: -35px !important ;
  }

  .lost-time-heading {
    font-size: 15px;
  }
}

/* ----------------------------- */

.profile__leftPanel {
  display: flex;
  height: 100vh;
  flex-direction: column;
  padding: 20px;
  margin: 30px 1px;
  margin-left: 0;
  flex: 0.25;
  background-color: #343a40;
  color: white;
}
.fa-user-circle {
  font-size: 100px;
  color: white;
  margin-bottom: 5px;
}
.profile__button {
  color: white;
  text-decoration: none;
  align-self: baseline;
  margin-left: 0;
  font-size: 14px !important;
}
.profile__button:hover {
  text-decoration: none;
  color: white;
  background-color: #32383e;
}
.profile__subButtons {
  color: white;
  align-self: baseline;
  margin: 0 20px;
  font-size: 14px;
  padding: 10px;
}
.profile__subButtons:hover {
  text-decoration: none;
  color: white;
  background-color: #32383e;
}

@media screen and (max-width: 550px) {
  .reportlost-one-line {
    flex-direction: column;
  }
  .reportlost-input {
    width: 100% !important;
  }
  .css-1nrlq1o-MuiFormControl-root {
    align-items: baseline !important;
  }
}

@media screen and (min-width: 48em) {
  .reportlost-loss-details-btns {
    padding: 0 16%;
  }

  .reportlost-location-input-cnt {
    top: 18em;
  }

  .reportlost-location-input-cnt-lat,
  .reportlost-location-input-cnt-lon {
    justify-content: space-evenly;
  }

  .reportlost-location-input-cnt-lat > span,
  .reportlost-location-input-cnt-lon > span {
    font-size: 1.375rem;
  }

  .reportlost-date-picker {
    width: 16.75em;
  }
}

@media screen and (min-width: 62.5em) {
  .reportLost-sidebar-toggle-btn-wrapper {
    display: none;
  }

  .reportLost-sidebar-container-hide {
    position: fixed;
    width: 15%;
    height: 100vh;
    left: 0;
    padding: 1rem;
  }

  .reportlost-location-input-cnt-lat,
  .reportlost-location-input-cnt-lon {
    justify-content: space-between;
  }
}

@media screen and (width: 64em) {
  .reportLost-right-wrapper {
    margin-left: 11em;
  }
}

@media screen and (min-width: 75em) {
  .reportLost-form-container {
    width: 38em;
    margin: 0 24rem;
  }

  .reportLost-right-wrapper {
    margin-left: 20vw;
  }
}

@media screen and (min-width: 78.125em) {
  .reportLost-sidebar-menu-link {
    font-size: 1rem;
  }

  .reportLost-sidebar-menu-ul-links {
    font-size: 0.875rem;
  }
}

@media screen and (min-width: 1200px) {
  .reportLost-main-form-div {
    width: 70%;
  }
}
@media screen and (max-width: 1200px) and (min-width: 1000px) {
  .reportLost-main-form-div {
    width: 70%;
    margin-left: 15rem;
  }
}
@media screen and (max-width: 1000px) {
  .reportLost-main-form-div {
    width: 90% !important;
    margin: auto !important;
  }
}
@media screen and (max-width: 570px) and (min-width: 490px) {
  .mapcontainer-row {
    width: 27rem !important;
  }
}
@media screen and (max-width: 490px) and (min-width: 440px) {
  .mapcontainer-row {
    width: 24rem !important;
  }
}
@media screen and (max-width: 440px) and (min-width: 380px) {
  .mapcontainer-row {
    width: 20rem !important;
  }
}
@media screen and (max-width: 380px) and (min-width: 300px) {
  .mapcontainer-row {
    width: 16rem !important;
  }
}
@media screen and (max-width: 300px) {
  .mapcontainer-row {
    width: 14rem !important;
  }
}
