.editParentDetails-main-wrapper {
  width: 100%;
  position: absolute;
  top: 5.3rem;
}

.editParentDetails-left-wrapper {
  position: relative;
}

.editParentDetails-sidebar-container {
  width: 100%;
  height: 100vh;
  z-index: 800;
  position: absolute;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  left: 0;
  transition: 350ms;
}

.editParentDetails-sidebar-container-hide {
  /* width: 100%; */
  height: 100vh;
  z-index: 800;
  position: absolute;
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  left: -100%;
  transition: 350ms;
  margin: -2rem 0 0 0;
}

.editParentDetails-sidebar-toggle-btn-wrapper {
  padding: 1rem;
  width: 100%;
}

.editParentDetails-sidebar-toggle-btn {
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
}

.editParentDetails-sidebar-menu-link {
  background-color: transparent;
  border: none;
  padding: 1rem;
  text-align: left;
  font-size: 1.21875rem;
}

.editParentDetails-sidebar-menu-ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.editParentDetails-sidebar-menu-ul-hide {
  display: none;
}

.editParentDetails-sidebar-menu-ul-links {
  border: none;
  background-color: transparent;
  padding: 1rem 1rem 1rem 2rem;
}

.editParentDetails-form-container-top {
  /* border: 1px solid brown; */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
}

.editParentDetails-user-img {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.editParentDetails-image {
  height: 10em;
  width: 10em;
  border-radius: 50%;
}

.editParentDetails-input-user-img {
  position: absolute;
  display: none;
}

.editParentDetails-preview-img {
  /* border: 1px solid red; */
  height: 7.5em;
  width: 7.5em;
  border-radius: 50%;
  position: absolute;
}

.editParentDetails-edit-button-image,
.editParentDetails-delete-button-image {
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid gray;
  padding: 0.3rem;
  z-index: 20;
  color: #000;
  padding: 9px 14px;
}

.editParentDetails-edit-button-image:hover {
  color: #fcbc1b;
}

.editParentDetails-delete-button-image:hover {
  color: #fcbc1b;
}

.editParentDetails-edit-button-image {
  position: absolute;
  top: 4em;
  left: 12em;
}

.editParentDetails-delete-button-image {
  position: absolute;
  top: 7em;
  left: 12em;
  background-color: white;
}

.editParentDetails-form {
  padding: 1rem;
}

.editParentDetails-input-wrapper {
  margin: 2px 4px;
  width: 28vw;
}

.editParentDetails-input-tag {
  padding: 1rem 2rem !important;
  border: 1px solid black;
  border-radius: 5px;
}
.gender-span,
.cc-span {
  margin: 1rem 0;
}
.editParentDetails-select-tag {
  background-color: white;
  border: 1px solid black;
  padding: 1rem 2rem !important;
  border-radius: 5px !important;
  width: 100%;
}
.editParentDetails-btn-row {
  display: flex;
  gap: 1.9em;
}

.body-login {
  width: 85%;
  border-radius: 15px;
  box-shadow: 0 4px 8px 0 rgba(20, 12, 12, 0.2),
    0 6px 10px 0 rgba(0, 0, 0, 0.19);
  margin-top: 15px;
}
.image {
  height: 120px;
  width: 120px;
  border-radius: 50px;
}
.overlay {
  height: 120px;
  width: 120px;
  background-color: black;
  margin-top: -120px;
  position: absolute;
  opacity: 0;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.button-image {
  background-color: transparent !important;
  color: white !important;
  border: none;
  outline: none;
}
.button-image:focus {
  background-color: transparent !important;
  color: white !important;
  border: none !important;
  outline: none !important;
}
.dashboard__blacksidenav {
  width: 23rem;
  background-color: #e7e4e4;
  position: sticky;
  top: 5rem;
}
.tag1_text {
  font-size: 17px;
  border: none;
  outline: none;
}
.my-tags {
  padding-left: 2rem;
}
.my-tags1 {
  padding-left: 2rem;
  width: 100%;
  height: 3rem;
  display: flex;
  justify-content: left;
  align-items: center;
}
.my-tags1:hover {
  cursor: pointer;
  background-color: rgb(251 249 249);
}
.background {
  min-height: 100% !important;
}
.image-upload {
  width: 120px;
  height: 120px;
  padding: 2rem 0rem 0rem 5rem;
}

#choose-profile-imageActi {
  display: none;
}

.image {
  height: 120px;
  width: 120px;
  border-radius: 50%;
  text-align: center !important;
  margin: auto !important;
}

.overlay {
  height: 120px;
  width: 120px;
  background-color: black;
  margin-top: -120px;
  position: absolute;
  opacity: 0;
  border-radius: 50%;
}
.button-image {
  background-color: transparent;
  border: none;
  outline: none;
}
.fa-edit {
  margin-top: 48px;
  color: white;
}
.plus-icon {
  margin-top: 0px;
  color: #a1a1a1;
}
.image-upload:hover .overlay {
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 1;
}
#uploadPreviewActi {
  width: 120px;
  height: 120px;
  margin-top: -140px !important;
  border-radius: 50%;
  border: none;
  outline: none;
}
#uploadPreview {
  width: 120px;
  height: 120px;
  margin-top: -140px !important;
  border-radius: 50%;
  display: none;
  border: none;
  outline: none;
}
textarea:focus {
  outline: none !important;
  box-shadow: none !important;
  border: 2px solid #e4e4e4 !important;
}
.label-petdata {
  font-size: 16px;
  color: #494949;
  font-weight: 800;
  font-family: "prompt, sans-serif";
}
p strong {
  font-size: 18px;
}

.color-gray {
  color: #b4b4b4;
}

::ng-deep .mat-radio-label-content {
  font-weight: 550 !important;
}
.submit-btn-editPet,
.cancel-btn-editPet {
  width: 100%;
  height: 40px !important;
  border: none;
  outline: none;
  border-radius: 5px;
  font-weight: 500;
}
.submit-btn-editPet {
  background-color: #fcbc1b !important;
}
.cancel-btn-editPet {
  background-color: white !important;
}
.cancel-btn-editPet:hover {
  background-color: #fcbc1b !important;
}

.file {
  border: none;
  outline: none;
  box-shadow: none;
  background-color: #fcbc1b;
  border-radius: 5px;
  width: 100px;
  height: 30px;
  font-size: 13px;
}

.gender-male {
  font-weight: none;
  font-size: 13px;
}

.gender-female {
  font-weight: none;
  font-size: 13px;
}

@media only screen and (max-width: 990px) {
  .marginheight {
    margin-top: 0px !important;
  }
  .dashboard__blacksidenav {
    height: 100% !important;
  }
  .petparentlinks {
    margin-top: -30px !important;
    margin-left: 1px !important;
  }
  .body-login {
    margin-top: 20px !important;
  }
}

@media only screen and (max-width: 850px) {
  .body-login {
    width: 100%;
  }
}

@media only screen and (max-width: 770px) {
  .label-petdata {
    text-align: center;
  }
  /* .label-petdata-1 {
    text-align: center;
  } */

  .gender-female {
    margin-top: -40px !important;
  }
  .below-gender-move {
    margin-top: 40px;
  }
  /* .leftside-navigation {
    margin-left: 15px !important;
  } */
  .marginheight {
    margin-top: 0px !important;
  }
}

@media only screen and (max-width: 576px) {
  .below-gender-move {
    margin-top: 0px;
  }
  .body-login {
    border-radius: 0px;
  }
  /* .left-side {
    border-radius: 0px 0 0 0px;
  } */
  .leftside-buttons {
    margin-left: 0px !important;
  }
  .petparentlinks {
    margin-left: -50px !important;
  }
}

@media only screen and (max-width: 510px) {
  .marginheight {
    margin-top: 30px !important;
  }
}

@media only screen and (max-width: 330px) {
  .gender-female {
    margin-top: -40px !important;
  }
  .below-gender-move {
    margin-top: 40px;
  }
  .leftside-buttons {
    width: 160px !important;
  }
  .petparentlinks {
    text-align: left !important;
    margin-left: -70px !important;
  }
}
/* Media Queries Start */
@media only screen and (max-width: 991px) {
  .dashboard__blacksidenav {
    width: 100%;
  }
  .left_pannel_options {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .my_tags_text {
    display: flex;
    width: 100%;
  }
  .my-tags {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .list_links {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0px;
  }
  /* .links_button {
    display: flex;
    flex-direction: column;
    width: 100%;
  } */
  .mytags1buttons {
    width: 100%;
  }
  .image-upload {
    padding: 2rem 1rem;
  }
}

@media screen and (min-width: 1010px) {
  .image-upload {
    margin-left: 2rem !important;
  }

  /* .mynavigator button {
    padding-left: 25px !important;
    padding-right: 25px !important;
  } */
}

@media screen and (min-width: 853px) {
  .image-upload {
    margin-left: 2rem !important;
  }
}

@media screen and (max-width: 500px) and (min-width: 425px) {
  .image-upload {
    margin-right: 12rem !important;
    /* margin-left: -3rem; */
  }
}

@media screen and (min-width: 62.5em) {
  .editParentDetails-sidebar-toggle-btn-wrapper {
    display: none;
  }

  .editParentDetails-sidebar-container-hide {
    position: fixed;
    width: 15%;
    height: 100vh;
    left: 0;
    padding: 1rem;
    color: whitesmoke;
  }
}

@media screen and (min-width: 78.125em) {
  .editParentDetails-sidebar-menu-link {
    font-size: 1rem;
  }

  .editParentDetails-sidebar-menu-ul-links {
    font-size: 0.875rem;
  }
}

/* @media screen and (min-width: 75em) {
  .editParentDetails-form-container {
    width: 38em;
    margin: 0 14rem;
  }
} */

/* Main Container */
.editParentDetails-main-wrapper {
  width: 100%;
  position: absolute;
  top: 5.3rem;
  background-color: hsla(0, 0%, 78%, 0.5058823529411764);
  padding: 1.5rem;
}

.editParentDetails-form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Main Right Container */
.editParentDetails-right-wrapper {
  border-radius: 10px;
  width: 90%;
  margin: 7rem;
  padding: 15px;
  background-color: #fff;
}

@media only screen and (min-width: 660px) {
  .editParentDetails-form-container {
    flex-direction: row;
  }
}

.two-field-wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: flex-start;
  gap: 1.5rem;
}

#demo-row-radio-buttons-group-label {
  margin-bottom: -0.5rem;
}

@media screen and (max-width: 480px) {
  .two-field-wrapper {
    flex-direction: column;
  }
  .editParentDetails-input-wrapper {
    margin: 4px 0px;
    width: 100%;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1200px) {
  .editParentDetails-right-wrapper {
    width: 70vw;
    margin-left: 15rem;
    padding: 1rem;
  }
  .editParentDetails-sidebar-container-hide {
    width: 20vw;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1300px) {
  .editParentDetails-right-wrapper {
    width: 52rem;
    margin-left: 16rem;
    padding: 1rem;
  }
}
@media screen and (min-width: 1300px) and (max-width: 1600px) {
  .editParentDetails-right-wrapper {
    width: 65rem;
    margin-left: 15rem;
    padding: 1rem;
  }
}
@media screen and (min-width: 1600px) and (max-width: 1800px) {
  .editParentDetails-right-wrapper {
    width: 70rem;
    margin-left: 20rem;
    padding: 1rem;
  }
}
@media screen and (min-width: 1800px) and (max-width: 2000px) {
  .editParentDetails-right-wrapper {
    width: 80rem;
    margin-left: 24rem;
    padding: 1rem;
  }
}
@media screen and (min-width: 2000px) and (max-width: 2300px) {
  .editParentDetails-right-wrapper {
    width: 85rem;
    margin-left: 25rem;
    padding: 1rem;
  }
}

@media screen and (min-width: 2300px) {
  .editParentDetails-right-wrapper {
    width: 95rem;
    margin-left: 27rem;
    padding: 1rem;
  }
}

@media screen and (max-width: 360px) {
  .editParentDetails-main-wrapper {
    /* width: min-content; */
    width: 100%;
  }
  .editParentDetails-right-wrapper {
    padding: 2.5rem;
    width: 100%;
    margin-bottom: 7rem;
  }
  .editParentDetails-sidebar-container-hide {
    left: -115%;
  }
}
@media (max-width: 768px) {
  .editParentDetails-right-wrapper {
    margin: 1rem;
  }
}
@media screen and (min-width: 1024px) {
  /* .editParentDetails-main-wrapper{
    height: 100vh;
  } */
}

.MuiFormGroup-root {
  flex-wrap: nowrap;
}

#demo-row-radio-buttons-group-label {
  margin-left: 14px;
}
.MuiFormGroup-row {
  margin-left: 14px;
}
