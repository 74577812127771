.main-wrapper {
  width: 100%;
  position: absolute;
  top: 11.3rem;
}

.left-wrapper {
  position: relative;
}

.sidebar-container {
  width: 100%;
  height: 100vh;
  z-index: 800;
  position: absolute;
  background-color: #e6e5e3;
  display: flex;
  flex-direction: column;
  left: 0;
  transition: 350ms;
}

.sidebar-container-hide {
  width: 100%;
  height: 100vh;
  z-index: 800;
  position: absolute;
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  left: -100%;
  transition: 350ms;
}

.sidebar-toggle-btn-wrapper {
  padding: 1rem;
  width: 100%;
}

.sidebar-toggle-btn {
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
}

.sidebar-menu-link {
  background-color: transparent;
  border: none;
  padding: 1rem;
  text-align: left;
  font-size: 1.21875rem;
}

.sidebar-menu-ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.sidebar-menu-ul-hide {
  display: none;
}

.sidebar-menu-ul-links {
  border: none;
  background-color: transparent;
  padding: 1rem 1rem 1rem 2rem;
}

.search-sort-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
}
.search-addrecord-div {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 999;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
}
.search-addrecord-div-hideShow {
  display: none;
}
.floating-hideShow-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.medicalDashboard-add-new-record-btn {
  background-color: #ffc107;
  padding: 0.5rem 0.7rem !important;
  border: none;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.4375em;
}

.medicalDashboard-add-new-record-btn:hover {
  background-color: #e2aa03;
}

.hide {
  left: -100%;
  transition: 850ms;
}
.hide-search {
  left: 100rem;
  transition: all ease-in-out 1000ms;
}

.collapse {
  display: none;
}

.background {
  height: fit-content !important;
  margin-top: -3rem;
}

.medicaldashboard-search-input {
  padding: 1.2em 1em;
  border: 1px solid #c4bcbc;
  border-radius: 50px;
  outline: none;
  position: absolute;
  top: 71.4px;
  right: -1px;
}

.sort-filter-div {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.med_con {
  margin-top: -0.3rem;
}

.pet-information-active {
  float: left;
  width: 100%;
  margin-top: 30px;
  /* background-color: rgba(231, 227, 227, 0.6); */
  background-color: white;
  border-radius: 15px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 10px;
}

.dropbtn {
  font-size: 18px !important;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  margin-left: -35px;
  margin-top: 5px;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-item-1 {
  width: 100% !important;
}

.dropdown:hover {
  display: block;
}

.dropdown {
  cursor: pointer;
  display: flex;
}

.ftr_opn {
  background-color: #e7e4e4;
  cursor: pointer;
  width: 250px;
  height: 30px;
}

option {
  background-color: #e7e4e4;
  cursor: pointer;
  width: 250px;
  height: 30px;
}

.dropdown-content > a {
  width: inherit;
  color: black;
  padding: 120px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content .dropdown-item-1:hover {
  background-color: #dddada;
  color: rgb(0, 0, 0);
  text-decoration: none;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: inherit;
}

.leftside-details {
  border: 2px solid rgb(8, 73, 194);
  border-radius: 15px;
  padding: 20px;
}

.leftside-blocks1 {
  height: 80px;
  width: 25px;
  border: 2px solid rgb(140, 140, 143);
  margin-right: 25px;
  margin-left: 30px;
}

.leftside-blocks2 {
  height: 80px;
  width: 25px;
  border: 2px solid rgb(140, 140, 143);
  margin-right: 30px;
}

.rightside-blocks {
  width: 30%;
  height: 100px;
  border-radius: 10px;
  background-color: #ffffff;
  border: 3px solid #cccccc;
  margin: 10px;
  display: inline-block;
  box-sizing: border-box;
  /* border: 1px solid red;
  height: 100px;
  width: 33%;
  margin: 10px;
  border: 2px solid rgb(175, 175, 175);
  background-color: white;
  padding-top: 5px !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.rightside-blocks-image {
  margin-left: -13px;
}

.rightside-vaccinations {
  font-size: 13px;
  margin-top: 30px;
}

.rightside-blocks-middle {
  font-size: 40px;
  font-weight: 500;
  padding: 10px 0;
}

.notification-text {
  font-size: 11px;
}

.fa-bell {
  margin-left: -5px;
  margin-top: 10px;
}

.rightside-button {
  width: 100%;
  border: none;
  outline: none;
  box-shadow: none;
  text-align-last: center;
}

.rightside-button-1 {
  width: 100%;
  border: none;
  outline: none;
  box-shadow: none;
}

.rightside-button-2 {
  width: 100%;
  border: none;
  outline: none;
  box-shadow: none;
}

.rightside-button-add-record {
  width: 100%;
  border: none;
  outline: none;
  box-shadow: none;
}

.rightside-medical-details {
  border: 2px solid rgb(202, 202, 202);
  margin-top: 30px;
  border-radius: 50px;
  margin-left: 50px;
  margin-right: 15px;
  width: 330px;
  min-width: 265px !important;
}

.rightside-medical-details-image {
  margin-left: 10px;
  margin-top: 3px;
}

.no_medical {
  text-align: center;
}

.text_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #737373;
  justify-content: center;
  align-items: center;
}
.medicaldetails {
  margin: auto;
}

button:focus {
  border: none;
  outline: none;
  box-shadow: none;
}

.rightside-medical-details-vaccination-info {
  color: rgb(156, 156, 156);
  font-size: 14px;
}

.darkgray {
  color: rgb(129, 129, 129);
}

.rightside-medical-details-vaccination-info1 {
  color: rgb(156, 156, 156);
  font-size: 14px;
}

.rightside-blocks-with-details-card {
  display: inline-block;
}
@media (max-width: 768px) {
  .main-wrapper {
    top: 7.3rem; /* Adjust top value for smaller screens */
  }
}
@media only screen and (max-width: 1400px) {
  .hidecols {
    display: none;
  }
}

@media only screen and (max-width: 1200px) {
  .right-wrapper {
    margin-left: 20%;
  }
}

@media only screen and (max-width: 1024px) {
  .rightside-blocks-with-details-card {
    margin-left: 10px !important;
  }

  .right-wrapper {
    margin: auto;
  }
}

@media only screen and (max-width: 993px) {
  .rightside-blocks-4 {
    margin-left: 50px;
  }

  .widthdecrease {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}

@media only screen and (max-width: 900px) {
  .rightside-blocks-with-details-card {
    margin-left: 30px !important;
  }
}

@media only screen and (max-width: 800px) {
  .rightside-blocks-with-details-card {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}

@media only screen and (max-width: 769px) {
  .rightside-blocks-4 {
    margin-left: 0px;
  }

  .rightside-blocks-3 {
    margin-left: 50px;
  }

  .rightside-blocks-5 {
    margin-left: 50px;
  }

  .rightside-blocks-with-details-card {
    margin-left: 100px !important;
    margin-right: 0px !important;
  }

  .main-logo-img {
    width: 50px !important;
    height: 50px !important;
  }

  .med-card-title {
    font-size: 12px !important;
  }
}

@media only screen and (max-width: 577px) {
  .rightside-blocks-with-details-card {
    margin-left: -50px !important;
    margin-right: 0px !important;
  }
}

@media only screen and (max-width: 412px) {
  .rightside-blocks-with-details-card {
    margin-left: -80px !important;
    margin-right: 0px !important;
  }
}

@media only screen and (max-width: 400px) {
  /* .rightside-medical-details-vaccination-notes {
    width: 200px !important;
  } */

  .rightside-medical-details {
    width: 250px !important;
  }
}

@media only screen and (max-width: 376px) {
  .body-medical-dashboard {
    margin-top: 50px !important;
  }

  .rightside-blocks-with-details-card {
    margin-left: -70px !important;
    margin-right: 20px !important;
  }
}

@media only screen and (max-width: 361px) {
  .body-medical-dashboard {
    margin-top: 50px !important;
  }

  .rightside-blocks-with-details-card {
    margin-left: -80px !important;
    margin-right: 20px !important;
  }
}

@media only screen and (max-width: 335px) {
  .rightside-blocks-with-details-card {
    margin-left: -90px !important;
    margin-right: 20px !important;
  }
}

@media only screen and (max-width: 320px) {
  .rightside-button-1 {
    margin-left: 0px !important;
  }

  .rightside-button-2 {
    margin-right: 10px !important;
  }

  .rightside-blocks-with-details-card {
    margin-left: -100px !important;
    margin-right: 20px !important;
  }
}

@media only screen and (max-width: 300px) {
  .rightside-blocks-with-details-card {
    margin-left: -110px !important;
    margin-right: 30px !important;
  }
}

@media only screen and (max-width: 282px) {
  .rightside-blocks-with-details-card {
    margin-left: -120px !important;
    margin-right: 45px !important;
  }
}

@media only screen and (max-width: 1320px) {
  .AddRecordDialog {
    width: fit-content !important;
    display: flex !important;
  }

  .Add_New_Record_Dialog {
    width: 400px !important;
    padding: 0px !important;
  }
}

@media only screen and (max-width: 992px) {
  .pet-information-active {
    padding: 1rem !important;
    margin: 1rem !important;
    width: 95% !important;
  }
  .overallbody {
    padding: 60px 100px !important;
  }

  .row-sort-filter {
    width: 800px !important;
    margin-top: 30px;
  }
}

@media only screen and (max-width: 920px) {
  .menu {
    margin-left: -50px !important;
  }
}

@media only screen and (max-width: 800px) {
  .overallbody {
    padding: 60px 20px !important;
  }

  .row-sort-filter {
    width: 700px !important;
  }
}

@media only screen and (max-width: 730px) {
  .overallbody {
    padding: 60px 20px !important;
  }

  .row-sort-filter {
    width: 600px !important;
  }
}

@media only screen and (max-width: 620px) {
  .overallbody {
    padding: 60px 10px !important;
  }

  .row-sort-filter {
    width: 500px !important;
  }
}

@media only screen and (max-width: 570px) {
  .medpetparentlinks {
    margin-left: -50px !important;
  }
}

@media only screen and (max-width: 520px) {
  .forsnallsizecss {
    display: block !important;
  }

  .forbigsizecss {
    display: none;
  }
}

@media only screen and (max-width: 500px) {
  .menu {
    margin-left: 0px !important;
  }
}

@media only screen and (max-width: 480px) {
  .row-sort-filter {
    width: 300px !important;
  }
  .rightside-blocks-middle {
    font-size: 30px;
  }
}
@media only screen and (max-width: 390px) {
  .rightside-blocks > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .rightside-vaccinations {
    margin-top: 0px;
  }
  .rightside-blocks-image {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 320px) {
  .row-sort-filter {
    width: 250px !important;
  }
}

@media only screen and (max-width: 290px) {
  .rightside-blocks-with-details-card {
    margin-left: -100px !important;
  }
}

/* Media Queries */

@media only screen and (max-width: 575px) {
  .row-sort-filter {
    width: 250px !important;
    text-align: left !important;
    /* background-color: aqua !important; */
  }

  .filter-div,
  .sort-div,
  .add-div {
    width: fit-content !important;
  }
}

@media only screen and (max-width: 522px) {
  .no-doc-upload {
    margin-left: -15px !important;
  }

  .remaining-info-div span {
    margin-left: 5px !important;
  }
}

@media only screen and (max-width: 480px) {
  .row-sort-filter {
    display: flex !important;
    flex-direction: column !important;
    margin-right: -3rem;
  }

  .sort-div {
    margin-left: 40px !important;
  }

  .filter-div {
    margin-left: 25px !important;
  }
  .rightside-blocks {
    width: calc(100% / 3 - 20px);
  }
}

@media only screen and (max-width: 400px) and (min-width: 280px) {
  .rightside-blocks {
    margin-right: 2px !important;
  }

  .dropdown-content {
    position: relative !important;
    width: 70% !important;
    height: fit-content !important;
    padding-top: 5px !important;
    margin-left: 20px !important;
    margin-right: -10px !important;
    padding-right: 0px !important;
    text-align: center !important;
  }

  .btn-ap-date {
    width: fit-content !important;
  }

  .dropdown-item-1 {
    padding-right: 0px !important;
    padding: 3px;
    text-align: justify !important;
    height: fit-content !important;
    font-size: 15px !important;
    margin-left: 30px !important;
  }

  .fa-arrow-down {
    margin-left: 5px !important;
  }

  .fa-arrow-up {
    margin-left: 10px !important;
  }
}

@media screen and (max-width: 768px) {
  .rightside-blocks {
    width: 40%;
    margin: 1em 0em 1em 2em;
    font-size: 12px;
  }
  /* .rightside-blocks-image {
    margin-left: 3rem;
    margin-left: 4rem;
  } */
}
@media only screen and (max-width: 400px) {
  .sort-div {
    text-align: left !important;
  }

  .dropdown-content {
    width: fit-content !important;
    margin-left: -50px !important;
    margin-right: 0px !important;
  }

  .dropdown-content button {
    margin-left: 0px !important;
  }
}

/* Media Queries for Responsive Cards Start */

@media only screen and (max-width: 521px) {
  .pet-information-active {
    background-color: white !important;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 10px !important;
    /* width: 325px !important; */
    /* margin-left: 35px !important; */
  }

  .my-p {
    display: flex !important;
    flex-direction: row !important;
  }

  .besides {
    float: right !important;
  }

  .innermost-info1,
  .innermost-info2 {
    flex-direction: row !important;
  }

  .reminder-doc {
    flex-direction: column !important;
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }

  .top-row {
    margin-left: 1px !important;
    margin-top: -1px !important;
    width: fit-content !important;
  }

  .med-card-title {
    position: relative !important;
    top: -65px !important;
    left: 85px !important;
    font-size: 15px !important;
  }

  .second-row {
    display: flex !important;
    flex-direction: row !important;
    /* background-color:greenyellow !important; */
    justify-content: space-between !important;
    margin-top: -60px !important;
    margin-left: 85px !important;
    width: 100% !important;
    color: rgba(0, 0, 0, 0.2) !important;
    font-size: 14px !important;
  }

  .float-left {
    margin-top: 0px !important;
  }

  .remaining-info-div {
    margin-top: -40px !important;
    display: flex !important;
    flex-direction: column !important;
  }

  .my-notes {
    margin-top: 10px !important;
  }

  .innermost-info2 {
    display: flex !important;
    flex-direction: row !important;
  }

  .doc-div {
    margin-top: 5px !important;
  }

  .diagnos-by,
  .diagnos-on {
    flex-direction: column !important;
  }

  .main-logo-img {
    height: 55px !important;
    width: 55px !important;
    margin-top: 1px !important;
  }

  .pet-info-inner {
    display: flex !important;
    flex-direction: column !important;
  }
}

@media screen and (width: 48em) {
  .medicalDashboard-right-wrapper {
    padding: 0 2rem;
  }
}

@media screen and (min-width: 62.5em) {
  .sidebar-toggle-btn-wrapper {
    display: none;
  }

  .sidebar-container-hide {
    position: fixed;
    width: 15%;
    height: 100vh;
    left: 0;
    padding: 1rem;
  }
}

@media screen and (width: 64em) {
  .medicalDashboard-right-wrapper {
    margin-left: 23%;
  }
}

@media screen and (min-width: 75em) {
  .medicalDashboard-right-wrapper {
    margin-left: 22%;
  }
}

@media screen and (min-width: 78.125em) {
  .sidebar-menu-link {
    font-size: 1rem;
  }

  .sidebar-menu-ul-links {
    font-size: 0.875rem;
  }
}
