.features {
  display: flex;
  padding: 50px 90px;
  background-color: #f1f1f1;
  padding-bottom: 120px;
}

.features > img {
  flex: 0.5;
  width: 400px;
  height: 300px;
}

/* ----- */
.list-of-features {
  margin-left: 100px !important;
  display: flex;
  text-align: left;
  background-color: #f7d308;
  padding-top: 15px;
  padding-right: 20px !important;
  width: 85% !important;
}
.list-of-features li {
  text-decoration: none !important;
  margin-top: 5px !important;
}
.list-of-features > ul {
  list-style-type: none;
  flex: 0.5;
  margin-right: 0px !important;
}
.list-of-features ul {
  margin-left: 15px !important;
  margin-bottom: 30px !important;
}

/* ----- */

.features__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.feature-button {
  border: none;
  font: 400 14px/20px Roboto, Helvetica Neue, sans-serif;
  font-size: 18px !important;
  font-weight: 500;
  padding: 12px 60px;
  border-radius: 3px;
  background-color: #f7d308;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
}

.feature-button:focus {
  background-color: #f7d308;
}

.features__buttons {
  display: flex;
  padding-top: 40px;
  margin-left: 100px !important;
}

.compare-button {
  border: none;
  font: 400 14px/20px Roboto, Helvetica Neue, sans-serif;
  font-size: 18px !important;
  font-weight: 500;
  padding: 12px 60px;
  border-radius: 3px;
  background-color: white;
}

.feature-button:hover {
  cursor: pointer;
  background-color: #e7c606;
}

.compare-button:hover {
  cursor: pointer;
  background-color: whitesmoke;
}

.features__list {
  display: flex;
  text-align: left;
  background-color: #f7d308;
  padding-top: 15px;
  /* padding-right: 30px; */
  margin-left: 100px;
  width: fit-content !important;
}

.features__list > ul {
  list-style-type: none;
  flex: 0.5;
}

.features__list > ul > li {
  display: flex;
  width: 350px;
  margin-bottom: 10px;
  align-content: center;
  flex-direction: column;
  margin-left: 20px;
  cursor: pointer;
}

.features__list > ul > div {
  margin-left: 75px;
  padding: 0;
}

h3 {
  font-weight: 550;
  font-size: 18px;
  padding-top: 3px;
}
.feature__header {
  display: flex;
}

.feature__description {
  margin-left: 45px;
}

.left__list {
  margin-left: 0 !important;
}

.list-bullets {
  color: #212529;
  font-size: 26px;
  margin-right: 20px;
}

@media only screen and (width: 1024px) {
  .features > img {
    margin-left: -30px !important;
  }
}

@media only screen and (max-width: 1024px) and (min-width: 780px) {
  .features {
    text-align: center !important;
  }
}

@media (max-width: 900px) {
  .features__list li {
    margin-top: 0px !important;
  }

  .features__list {
    flex-direction: column;
    margin-left: 0;
  }
  .list-of-features {
    flex-direction: column !important;
    padding-left: 5px !important;
    margin: auto !important;
    width: 100% !important;
  }
  .features__buttons {
    margin: auto !important;
  }
  .list-2 {
    margin-top: -22px !important;
  }
}

@media only screen and (max-width: 600px) {
  /* .compare-button
    {
        display: none !important;
    } */
}

@media only screen and (max-width: 500px) {
  .list-of-features {
    width: 100% !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .features {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
}

@media only screen and (max-width: 480px) {
  .features img {
    /* margin-left: 10px !important; */
    margin: auto !important;
    margin-left: 40px !important;
    text-align: center;
    width: 290px !important;
    height: 250px !important;
  }
  .compare {
    width: fit-content !important;
  }

  .features__list {
    width: fit-content !important;
  }
  .left__list {
    width: fit-content !important;
  }
}

@media only screen and (max-width: 768px) and (min-width: 480px) {
  .features {
    text-align: center !important;
  }
}

/* @media only screen and (max-width:420px) {

    .features__buttons
    {
        margin-left: -30px !important;
    }
    
} */

@media only screen and (max-width: 380px) {
  .compare-button,
  .feature-button {
    padding: 15px 20px !important;
  }
}

@media only screen and (max-width: 378px) {
  .features img {
    margin-left: 10px !important;
  }
}

@media only screen and (max-width: 365px) {
  .features img {
    margin-left: 15px !important;
  }
}

@media only screen and (max-width: 350px) {
  .features__list li {
    background-color: blue !important;
  }

  .features__list li fa-check-circle {
    margin-top: 20px !important;
  }
}

@media only screen and (max-width: 350px) and (min-width: 275px) {
  .features img {
    margin-left: 10px !important;
    width: 200px !important;
    height: 180px !important;
  }
  .bullet-btn {
    font-size: 25px !important;
    margin-top: 4px !important;
  }

  .features__list li {
    padding-top: -10px !important;
  }
}
